<template>
    <div class="w-full flex justify-center items-center">
        <div class="certification">
            <div class="text-gray-500 text-left path sm:my-3">首页-个人中心-实名认证</div>
            <div>
                <div class="mt-8 flex sm:justify-center equipment duration-500">
                    <el-form ref="form" label-width="80px" label-position="left">
                        <el-form-item label="姓名" class="flex justify-start">
                            <el-input
                                v-model="authName"
                                placeholder="请输入您的姓名"
                                class="form_input duration-500"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="手机号" class="flex justify-start">
                            <el-input
                                v-model="phone"
                                placeholder="请输入您的联系方式"
                                class="form_input duration-500"
                            ></el-input>
                        </el-form-item>

                        <el-form-item label="身份证号" class="flex justify-start">
                            <el-input
                                v-model="authIdCode"
                                placeholder="请输入您的身份证号码"
                                class="form_input duration-500"
                            ></el-input>
                        </el-form-item>

                        <el-form-item label="身份证照" class="flex justify-start">
                            <div class="flex items-center">
                                <el-upload
                                    class="avatar-uploader mr-2"
                                    :action="qiniuUrl"
                                    :show-file-list="false"
                                    :on-success="frontUploadSuccess"
                                    :before-upload="beforeAvatarUpload"
                                    :data="uploadData"
                                >
                                    <img v-if="forntImage" :src="forntImage" class="avatar" />
                                    <div class="flex justify-center items-center h-full" v-else>
                                        <div>
                                            <div class="flex justify-center items-center">
                                                <img
                                                    class="upload_icon"
                                                    src="../../../../assets/images/personal/add.png"
                                                    alt
                                                />
                                            </div>

                                            <div
                                                class="upload_text flex justify-center items-center"
                                            >身份证人面</div>
                                        </div>
                                    </div>
                                </el-upload>

                                <el-upload
                                    class="avatar-uploader"
                                    :action="qiniuUrl"
                                    :show-file-list="false"
                                    :on-success="behindUploadSuccess"
                                    :before-upload="beforeAvatarUpload"
                                    :data="uploadData"
                                >
                                    <img v-if="behindImage" :src="behindImage" class="avatar" />
                                    <div class="flex justify-center items-center h-full" v-else>
                                        <div>
                                            <div class="flex justify-center items-center">
                                                <img
                                                    class="upload_icon"
                                                    src="../../../../assets/images/personal/add.png"
                                                    alt
                                                />
                                            </div>

                                            <div
                                                class="upload_text flex justify-center items-center"
                                            >身份证国徽面</div>
                                        </div>
                                    </div>
                                </el-upload>
                            </div>
                        </el-form-item>
                        <el-form-item label="." class="flex justify-start btn_item">
                            <el-button
                                type="primary"
                                @click="userAuthCheckRules"
                                class="btn duration-500"
                            >提交</el-button>
                            <!-- <el-button style="width:200px">解绑</el-button> -->
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            authIdCode: "",// 身份证
            authName: "",// 姓名
            phone: "",
            idCodeDown: "", // 身份证（国徽面）
            idCodeUp: "", // 身份证（人面）

            // 图片上传的相关属性
            uploadData: {
                token: ""
            },
            front: null,
            forntImage: "",
            behind: null,
            behindImage: "",
            certification: {
                authIdCode: "",
                authName: "",
                idCodeDown: "",
                idCodeUp: "",
                userAuthId: '',
                userId: ""
            }
        };
    },
    created() {
        this.uploadData.token = this.getQiniuToken
        this.userAuthDetail()
    },
    computed: {
        ...mapGetters(['getQiniuToken', 'getQinniuUrl'])
    },
    methods: {
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG(PNG) 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        // 上传成功
        frontUploadSuccess(res, file) {
            console.log('图片上传front', res)
            this.front = res
            this.forntImage = URL.createObjectURL(file.raw);
        },
        behindUploadSuccess(res, file) {
            console.log('图片上传behind', res)
            this.behind = res
            this.behindImage = URL.createObjectURL(file.raw);
        },
        // 实名校验
        userAuthCheckRules() {
            if (this.isNull(this.authName)) {
                this.$EventBus.$emit("toast", { type: 'error', message: '姓名不能为空' })
                return
            }
            if (!this.checkPhone(this.phone)) {
                this.$EventBus.$emit("toast", { type: 'error', message: '手机号码错误' })
                return
            }
            if (this.isNull(this.authIdCode)) {
                this.$EventBus.$emit("toast", { type: 'error', message: '身份证号码不能为空' })
                return
            }
            if (this.forntImage == "") {
                this.$EventBus.$emit("toast", { type: 'error', message: '请上传身份证人物面照片' })
                return
            }
            if (this.behindImage == "") {
                this.$EventBus.$emit("toast", { type: 'error', message: '请上传身份证国徽面照片' })
                return
            }
            this.certification.authIdCode ? this.userAuthUpdate() : this.userAuth()
        },
        // 实名认证
        userAuth() {
            let data = {
                authIdCode: this.authIdCode,
                authName: this.authName,
                phone: this.phone,
                idCodeDown: this.getQinniuUrl + "/" + this.behind.key,
                idCodeUp: this.getQinniuUrl + "/" + this.front.key,
            }
            // if (this.forntImage == this.certification.idCodeUp) {
            //     data.idCodeUp = this.forntImage
            // } else {
            //     data.idCodeUp = this.getQinniuUrl + "/" + this.front.key
            // }
            // if (this.behindImage == this.certification.idCodeDown) {
            //     data.idCodeDown = this.behindImage
            // } else {
            //     data.idCodeDown = this.getQinniuUrl + "/" + this.behind.key
            // }
            this.$request({
                method: "post",
                url: this.$requestPath.userAuth,
                data
            }).then(res => {
                if (res.code == 0) {
                    console.log(res)
                    this.$EventBus.$emit("toast", { type: 'success', message: '实名认证成功' })
                    this.userAuthDetail()
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: '实名认证失败，请稍后再试' })
                }
            }).catch(error => {
                console.log("实名认证error", error)
            })
        },
        // 实名认证信息
        userAuthDetail() {
            this.$request({
                method: "get",
                url: this.$requestPath.userAuthDetail,
                data: {}
            }).then(res => {
                console.log(res)
                if (res.code == 0) {
                    this.certification = Object.assign({}, this.certification, res.data)
                    this.authIdCode = this.certification.authIdCode
                    this.authName = this.certification.authName
                    this.forntImage = this.certification.idCodeUp
                    this.behindImage = this.certification.idCodeDown
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: '获取实名认证信息失败，请稍后再试' })
                }
            }).catch(error => {
                console.log("获取实名认证error", error)
            })
        },
        // 实名认证修改
        userAuthUpdate() {
            let data = {
                authIdCode: this.authIdCode,
                authName: this.authName,
                phone: this.phone,
                userAuthId: this.certification.userAuthId,
                userId: this.certification.userId
            }
            if (this.forntImage == this.certification.idCodeUp) {
                data.idCodeUp = this.forntImage
            } else {
                data.idCodeUp = this.getQinniuUrl + "/" + this.front.key
            }
            if (this.behindImage == this.certification.idCodeDown) {
                data.idCodeDown = this.behindImage
            } else {
                data.idCodeDown = this.getQinniuUrl + "/" + this.behind.key
            }
            this.$request({
                method: "put",
                url: this.$requestPath.userAuthUpdate,
                data
            }).then(res => {
                if (res.code == 0) {
                    console.log(res)
                    this.$EventBus.$emit("toast", { type: 'success', message: '实名认证修改成功' })
                    this.userAuthDetail()
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: '实名认证修改失败，请稍后再试' })
                }
            }).catch(error => {
                console.log("实名认证修改error", error)
            })
        },
    },
};
</script>


<style lang='scss' scoped>
.certification {
    width: 100%;
    max-width: var(--max-width);
    padding: 0 var(--margin-base);
    min-height: var(--min-height);
}

@media screen and (max-width: 640px) {
    .path {
        margin-top: 0.625rem;
    }
    .form_input {
        width: 15.625rem;
    }
    .btn {
        width: 6.25rem;
    }
    .upload_icon {
        width: 1rem;
    }
    .upload_text {
        height: 30px;
        font-size: 0.75rem;
    }
}
@media screen and (max-width: 900px) {
    .pc_show {
        display: none;
    }
    .title {
        font-size: 1.25rem;
    }
}
@media screen and (min-width: 640px) {
    .form_input {
        width: 18.75rem;
    }
    .btn {
        width: 12.5rem;
    }
    .upload_icon {
        width: 20px;
    }
    .upload_text {
        height: 30px;
    }
}
@media screen and (min-width: 900px) {
    .pc_show {
        display: block;
    }
    .title {
        font-size: 1.875rem;
    }
}
</style>
<style lang="scss">
.btn_item label {
    opacity: 0;
}
.equipment .el-form-item__content {
    margin-left: 0.625rem !important;
}
@media screen and (max-width: 640px) {
    .el-upload--picture-card {
        width: 6.25rem;
        height: 6.25rem;
    }
    .el-upload {
        width: 6.25rem;
        height: 6.25rem;
        line-height: 6.25rem;
    }
    .el-upload-list--picture-card .el-upload-list__item {
        width: 6.25rem;
        height: 6.25rem;
        line-height: 6.25rem;
    }
    .el-upload-list--picture-card .el-upload-list__item-thumbnail {
        width: 6.25rem;
        height: 6.25rem;
        line-height: 6.25rem;
    }
    .avatar {
        width: 6.25rem;
        height: 6.25rem;
    }
}
.btn_item label {
    opacity: 0;
}
.equipment .el-form-item__content {
    margin-left: 0.625rem !important;
}
.avatar-uploader .el-upload {
    border: 0.0625rem dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
@media screen and (max-width: 640px) {
    .avatar-uploader,
    .el-upload {
        width: 6.25rem;
        height: 6.25rem;
    }
    .avatar-uploader-icon {
        font-size: 1.75rem;
        color: #8c939d;
        width: 6.25rem;
        height: 6.25rem;
        line-height: 6.25rem;
        text-align: center;
    }
    .avatar {
        width: 6.25rem;
        height: 6.25rem;
        display: flex;
        justify-content: center;
        align-content: center;
    }
}
@media screen and (min-width: 640px) {
    .avatar-uploader,
    .el-upload {
        width: 148px;
        height: 148px;
    }
    .avatar-uploader-icon {
        font-size: 1.75rem;
        color: #8c939d;
        width: 148px;
        height: 148px;
        line-height: 148px;
        text-align: center;
    }
    .avatar {
        width: 148px;
        height: 148px;
        display: block;
    }
}
</style>
